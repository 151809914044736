import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const PolitiqueConfidentialitePageTemplate = ({ title, image, content, contentComponent, helmet }) => {
  const PageContent = contentComponent || Content

  return (
    <section className="section section--gradient">
      {helmet || ''}
      <div className="container is-max-desktop">
        <h1
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen has-text-centered"
          style={{
            lineHeight: '1',
            padding: '0.25em',
          }}
        >
          {title}
        </h1>
        <img
          src={!!image.childImageSharp ? image.childImageSharp.fluid.src : image}
          style={{borderRadius: '10px'}}
        />
        <br/>
        <div className="columns">
          <div className="column">
            <div className="py-3">
              <PageContent className="content" content={content} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

PolitiqueConfidentialitePageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const PolitiqueConfidentialitePage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout pagetitle={post.frontmatter.title}>
      <PolitiqueConfidentialitePageTemplate
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="%s | GMF Centre Médical St-Hubert">
            <title>{`${post.frontmatter.title}`}</title>
          </Helmet>
        }
        title={post.frontmatter.title}
        image={post.frontmatter.image}
        content={post.html}
      />
    </Layout>
  )
}

PolitiqueConfidentialitePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PolitiqueConfidentialitePage

export const politiqueConfidentialitePageQuery = graphql`
  query PolitiqueConfidentialitePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
